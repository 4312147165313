import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import SEO from 'components/SEO'
import Header from 'components/Header'
import FullWidthSection from 'components/FullWidthSection'
import ContentSection from 'components/ContentSection'
import ImageCards from './ImageCards'
import NavBar from 'components/NavBar'
import Layout from 'components/Layout'

export const FreightPageTemplate = ({
  header,
  section1,
  section2,
  section3
}) => (
  <Fragment>
    <Header
      images={header.images}
      number={header.number}
      title={header.title}
      subtitle={header.subtitle}
      anchor={header.scrollAnchor}
    />
    <FullWidthSection
      title={section1.title}
      text={section1.text}
      anchor={header.scrollAnchor}
    />
    <ImageCards cards={section2.modes} />
    <ContentSection
      image={section3.image.childImageSharp}
      title={section3.title}
      subtitle={section3.subtitle}
      text={section3.text}
      button={{
        path: section3.buttonPath,
        text: section3.buttonText
      }}
    />
  </Fragment>
)

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, structuredData } = frontmatter
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
        structuredData={structuredData}
      />
      <Helmet title={seo.title} />
      <NavBar />
      <FreightPageTemplate
        header={frontmatter.header}
        section1={frontmatter.section1}
        section2={frontmatter.section2}
        section3={frontmatter.section3}
      />
    </Layout>
  )
}

export const query = graphql`
  query FreightPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seo {
          title
          description
          url
        }
        structuredData {
          context
          type
          url
          name
          address
          telephone
          priceRange
          additionalProperty
          openingHours
          areaServed
          email
          legalName
          alternateName
          description
        }
        header {
          title
          subtitle
          images {
            mobile {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            desktop {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          number
          scrollAnchor
        }
        section1 {
          title
          text
        }
        section2 {
          modes {
            image {
              childImageSharp {
                sizes(maxWidth: 1500) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            number
            title
            subtitle
          }
        }
        section3 {
          title
          subtitle
          text
          image {
            childImageSharp {
              sizes(maxWidth: 1500) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          buttonPath
          buttonText
        }
      }
    }
  }
`
