import React, { Fragment } from 'react'
import styled from 'styled-components'

import GatsbyImageLoader from 'components/GatsbyImageLoader'

export default ({ cards }) => (
  <Fragment>
    <Container>
      {cards.map((card, i) => {
        return (
          <GatsbyImageLoader key={i} sizes={card.image.childImageSharp.sizes}>
            {({ src }) => (
              <CardContainer>
                <Card image={src} />
                <Number>{card.number}</Number>
                <Heading>{card.title}</Heading>
                <Subtitle>{card.subtitle}</Subtitle>
              </CardContainer>
            )}
          </GatsbyImageLoader>
        )
      })}
    </Container>
  </Fragment>
)
const Container = styled.div`
  width: 100%;
  height: 320px;
  padding: 0 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.desktop}px) {
    flex-direction: column;
    height: auto;
  }
`
const CardContainer = styled.div`
  width: calc(25% - 8px);
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 100%;
    height: 200px;
    margin: 4px;
  }
`
const Card = styled.div`
  position: absolute;
  top: 0;
  margin: -2.5%;
  width: 105%;
  height: 120%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  filter: brightness(0.4) contrast(1);
  transition-duration: 0.5s;
  padding: 30px;
  z-index: 1;

  @media (max-width: ${props => props.theme.tablet}px) {
    filter: brightness(0.6);
  }
`
const Number = styled.h1`
  position: relative;
  margin-left: 25px;
  color: white;
  font-size: 26pt;
  font-weight: ${props => props.theme.book};
  pointer-events: none;
  z-index: 2;
`
const Heading = styled.h1`
  position: relative;
  margin-left: 20px;
  color: white;
  font-size: 30pt;
  font-weight: ${props => props.theme.ultra};
  pointer-events: none;
  width: 100%;
  z-index: 2;
`
const Subtitle = styled.h2`
  margin-left: 25px;
  color: white;
  font-size: 14pt;
  font-weight: ${props => props.theme.book};
  pointer-events: none;
  width: 100%;
  position: relative;
  z-index: 2;

  @media (max-width: 1050px) {
    width: 60%;
  }
`
